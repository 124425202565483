<script setup>
import {onMounted, onBeforeUnmount} from 'vue'

const props = defineProps({
  label1: {
    type: String,
    default: 'KATARRAKTIS'
  },
  label2: {
    type: String,
    default: 'WEATHER'
  },
  theme: {
    type: String,
    default: 'blank'
  }
})

const WIDGET_SCRIPT_ID = 'weatherwidget-io-js'
const {locale} = useI18n();
const link = computed(() => {
  return `https://forecast7.com/${locale.value}/38d2626d10/katarraktis/`
})

const loadWeatherWidget = () => {
  if (!document.getElementById(WIDGET_SCRIPT_ID)) {
    const script = document.createElement('script')
    script.id = WIDGET_SCRIPT_ID
    script.src = 'https://weatherwidget.io/js/widget.min.js'
    script.setAttribute('cache-control', 'max-age=3600') // Cache for 1 hour
    document.head.appendChild(script)
  } else {
    // If script already exists, reinitialize the widget
    window.__weatherwidget_init && window.__weatherwidget_init()
  }
}

const cleanup = () => {
  const script = document.getElementById(WIDGET_SCRIPT_ID)
  if (script) {
    script.remove()
  }
}

onMounted(() => {
  loadWeatherWidget()
})

onBeforeUnmount(() => {
  cleanup()
})
</script>

<template>
  <KeepAlive>
    <a
        class="weatherwidget-io"
        :href="link"
        :data-label_1="label1"
        :data-label_2="label2"
        :data-theme="theme"
    >
      {{ label1 }} {{ label2 }}
    </a>
  </KeepAlive>
</template>

<style scoped lang="sass">
.weatherwidget-io
  display: block
  width: 60%
  transition: all 150ms ease

.dark-mode .weatherwidget-io
  display: block
  filter: invert(100%)

@media (max-width: 480px)
  .weatherwidget-io
    display: block
    width: 100%
</style>